<template>
  <b-overlay :show="!isLoading" rounded="sm">
    <b-container fluid>
      <AnalyticsHeader
        headerTitle="LOCATION DASHBOARD"
        @onDateChange="updateDate"
        @extractAnalysis="extractAnalysis"
      />
      <div>
        <b-row :key="i" v-for="(group, i) in locationList">
          <b-col
            v-for="(item, key) in locationList.slice(
              i * itemsPerRow,
              (i + 1) * itemsPerRow
            )"
            :key="key"
            class="text-center"
          >
            <b-card>
              <div class="d-flex justify-content-between">
                <span class="font-medium-1">
                  {{ item.label }}
                </span>
                <b class="font-medium-1">
                  {{ item.value }}
                </b>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        ></b-spinner>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import AnalyticsHeader from "./components/AnalyticsHeader.vue";

export default {
  components: {
    AnalyticsHeader,
  },

  created() {
    //fetch chart data
    this.getAnalyticsData();
    this.isLoading = false;
  },

  methods: {
    updateDate(date, type) {
      if (type === "FROM") {
        this.fromDate = date;
      } else {
        this.toDate = date;
      }
      this.getAnalyticsData();
    },

    async extractAnalysis() {
      const response = await this.$http.get(
        `analytics_report/location/${this.fromDate}/${this.toDate}`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `LocationAnalytics_${this.fromDate}-${this.toDate}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    async getAnalyticsData() {
      this.isLoading = false;
      let response = await this.$http.get(
        `analytics/location/${this.fromDate}/${this.toDate}`
      );
      if (response.status) {
        this.locationList[0].value = response.data.data.perlis;
        this.locationList[1].value = response.data.data.pulau_pinang;
        this.locationList[2].value = response.data.data.kedah;
        this.locationList[3].value = response.data.data.perak;
        this.locationList[4].value = response.data.data.pahang;
        this.locationList[5].value = response.data.data.negeri_sembilan;
        this.locationList[6].value = response.data.data.melaka;
        this.locationList[7].value = response.data.data.johor;
        this.locationList[8].value = response.data.data.selangor;
        this.locationList[9].value = response.data.data.kuala_lumpur;
        this.locationList[10].value = response.data.data.terengganu;
        this.locationList[11].value = response.data.data.kelantan;
        this.locationList[12].value = response.data.data.sabah;
        this.locationList[13].value = response.data.data.sarawak;
        this.locationList[14].value = response.data.data.singapore;
      } else {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.isLoading = true;
    },
  },

  computed: {
    iconGroups() {
      return Array.from(
        Array(Math.ceil(this.locationList.length / this.itemsPerRow)).keys()
      );
    },
  },

  data() {
    return {
      fromDate: new Date(
        Date.now() - 5 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-CA"),
      toDate: new Date().toLocaleDateString("en-CA"),
      isLoading: false,
      itemsPerRow: 3,
      locationList: [
        {
          label: "Perlis",
          value: 0,
        },
        {
          label: "Pulau Pinang",
          value: 0,
        },
        {
          label: "Kedah",
          value: 0,
        },
        {
          label: "Perak",
          value: 0,
        },
        {
          label: "Pahang",
          value: 0,
        },
        {
          label: "Negeri Sembilan",
          value: 0,
        },
        {
          label: "Melaka",
          value: 0,
        },
        {
          label: "Johor",
          value: 0,
        },
        {
          label: "Selangor",
          value: 0,
        },
        {
          label: "Kuala Lumpur",
          value: 0,
        },
        {
          label: "Terengganu",
          value: 0,
        },
        {
          label: "Kelantan",
          value: 0,
        },
        {
          label: "Sabah",
          value: 0,
        },
        {
          label: "Sarawak",
          value: 0,
        },
        {
          label: "Singapore",
          value: 0,
        },
      ],
    };
  },
};
</script>

<style></style>
